import BaseHeader from "@olmokit/components/Header";
import { Selectlang } from "components/Selectlang";
import { $, $all, on, forEach, addClass, removeClass } from "@olmokit/dom";
import threshold from "@olmokit/core/scroll/threshold";
import StickySidebar from "@olmokit/core/scroll/stickySidebar";
import { initCart } from "@olmokit/core/cart";
// import "utils/logo.scss";
import "./index.scss";

/**
 * Component: Header
 *
 */
export function Header() {
  const header = BaseHeader();
  Selectlang();
  initCart();

  const $labels = $all('.Header:nav-label');
  const $submenus = $all('.Header:nav-submenu');

  const $categories = $all('.Header:nav-submenu__link');
  const $categoriesImage = $all('.Header:nav-submenu__image');

  forEach($labels, (label, index) => {
    on(label, 'mouseover', (e) => {
      checkIsActiveAndAdd(label);      
      addClass($submenus[index], 'is-open');
    });
    on(label, 'mouseleave', (e) => {
      removeClass($submenus[index], 'is-open');
    });    
  });

  forEach($categories, (category, index) => {
    on(category, 'mouseover', (e) => {
      checkIsActiveAndRemove();
      addClass($categoriesImage[index], 'is-active');
    });
  });  

  function checkIsActiveAndAdd(label) {
    const $category = $all('.Header:nav-submenu__link', label);
    const $categoryImage = $all('.Header:nav-submenu__image', label);    

    
    let counter = 0;
    forEach($category, (category, index) => {
      if(category.classList.contains('is-active')){
        counter++;
      }
    });
    
    if(counter == 0){
      addClass($categoryImage[0], 'is-active');
    }
  }

  function checkIsActiveAndRemove() {
    forEach($categories, (category, index) => {
      removeClass($categoriesImage[index], 'is-active');
    });
  }

  // new StickySidebar(".Header:", {
  //   topSpacing: 0,
  //   dimensions: { sidebarHeight: '90px' }
  // }); 

  const $header = $(".Header:");
  threshold(1, {target: $header});

  return {
    /**
     * Set variant
     *
     * @param {"light" | "dark"} variant
     */
    setVariant(variant) {
      const { className } = header.$root;
      header.$root.className = className.replace(
        /(is-).+(\s*)/,
        `$1${variant}$2`
      );
    },
  };
}
